import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CSectTitle03,
  CInfinitySlider03,
  COverflowTable,
  CSimpleCard,
  CTable,
  CBtnList,
  CInlineLinkList,
  LVenue,
  CFixedImg,
  LBanquetContact,
} from '../../../components/_index';
// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type="ja"
        data={{
          title: {
            main: <>中宴会場 春海</>,
            sub: <>HARUMI</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/harumi/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/harumi/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <div className="u_pt50 u_mbLarge">
        <LWrap>
          <CInfinitySlider03
            data={[
              {
                img: {
                  src: '/assets/images/banquet/harumi/img_banquet.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/banquet/harumi/img_banquet02.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/banquet/harumi/img_banquet03.png',
                  alt: '',
                },
              },
            ]}
          />
          <p className="u_mbMedium">
            春の淡いローズ色とブリティッシュグリーンを基調にし、壁と同色の花びらを配したカーペット。2つが美しく調和するダイニングです。
            <br />
            円形の泡入りガラスシャンデリアと春の海の壮大な壁画が楽しいお食事の時間を華やかに演出します。
          </p>
          <COverflowTable>
            <table className="c_fixedTable">
              <thead>
                <tr>
                  <th rowSpan={2}>スタイル</th>
                  <th colSpan={4}>ご人数</th>
                  <th rowSpan={2}>広さ(m²)</th>
                  <th rowSpan={2}>天井高</th>
                </tr>
                <tr>
                  <th>正餐</th>
                  <th>立食</th>
                  <th>スクール</th>
                  <th>シアター</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>全室</th>
                  <td>180</td>
                  <td>180</td>
                  <td>180</td>
                  <td>340</td>
                  <td>320</td>
                  <td>3</td>
                </tr>
                <tr>
                  <th>春海A</th>
                  <td>60</td>
                  <td>80</td>
                  <td>65</td>
                  <td>100</td>
                  <td>150</td>
                  <td>3</td>
                </tr>
                <tr>
                  <th>春海B</th>
                  <td>70</td>
                  <td>80</td>
                  <td>90</td>
                  <td>170</td>
                  <td>170</td>
                  <td>3</td>
                </tr>
              </tbody>
            </table>
          </COverflowTable>
        </LWrap>
      </div>
      <section className="u_mbLarge">
        <LWrap>
          <CSectTitle03 title="レイアウト例" />
          <CSimpleCard
            data={[
              {
                img: {
                  src: '/assets/images/banquet/harumi/img_example.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/banquet/harumi/img_example02.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/banquet/harumi/img_example03.png',
                  alt: '',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <section>
        <LWrap>
          <CSectTitle03 title="主要設備" />
          <CTable
            data={[
              {
                title: '音響',
                content: (
                  <>
                    <ul className="c_circleList">
                      <li>オーディオミキサー6ch</li>
                      <li>カセットデッキプレーヤー</li>
                      <li>CDプレーヤー</li>
                      <li>スピーカー</li>
                      <li>マイクロフォン</li>
                      <li>ワイヤレスマイクロフォン</li>
                    </ul>
                  </>
                ),
              },
              {
                title: '照明',
                content: (
                  <>
                    <ul className="c_circleList">
                      <li>調光卓＜2段プリセット24ch(サブマス10ch)＞</li>
                      <li> 演出調光回路 20A</li>
                      <li>ピンスポットライト</li>
                      <li>
                        仮設電源盤
                        <br />
                        主幹 1φ3W 100/200V 200A 分岐 1φ3W 100/200V 75A×3
                        主幹3φ3W 200V 100A 分岐 3φ3W 200V 50A×2
                      </li>
                    </ul>
                  </>
                ),
              },
              {
                title: 'その他',
                content: (
                  <>
                    プロジェクター※有料
                    <br />
                    スクリーン※有料
                    <br />
                    看板・案内板※有料
                    <br />
                    同時通訳システム※有料
                  </>
                ),
              },
            ]}
          />
          <CBtnList
            data={[
              {
                label: 'CO₂ゼロ MICE®のご案内',
                size: 'large',
                link: {
                  href: '/banquet/co2zero_mice/',
                },
                color: 'borderBlack',
              },
            ]}
          />
          <CInlineLinkList
            exClass="u_mt30"
            data={[
              {
                label: 'Web会議システム',
                link: {
                  href: '/banquet/on-line_meeting/',
                  blank: false,
                },
                //icon: 'document',
              },
            ]}
          />
        </LWrap>
      </section>
      <LVenue />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宴会・会議',
              path: '/banquet/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
